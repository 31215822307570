import { FakeService		} from './../fake/fake.service';
import { Injectable 		} from '@angular/core';
import { HttpClient 		} from '@angular/common/http';
import { ToastController, 
		 Platform, 
		 AlertController 	} from '@ionic/angular';
import { TranslateService 	} from '@ngx-translate/core';
import { Camera, 
		 CameraOptions 		} from '@ionic-native/camera/ngx';
import { ScreenOrientation 	} from '@ionic-native/screen-orientation/ngx';
import { Vibration 			} from '@ionic-native/vibration/ngx';
import { routes 			} from './routes';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class CommonsService {
	project				: string	= "Tourinia";
	simulation			: boolean 	= false;
	userInfo			: any 		= {};
	pageInfo			: any		= {
		version			: "0.9.10",
		simulate		: true,
		simulateSteptime: 500,
		voucherCode		: 'K1611267',
		flight			: {
			gate	:	'D96',
			belt	:	'01'
		},
		providerLogo	: "assets/img/otbt.png",
		providers		: [
			{
				id 			: 1,
				img 		: 'https://media.licdn.com/dms/image/C560BAQGi-zN4Tzg3aw/company-logo_200_200/0?e=2159024400&v=beta&t=HHFJcttZR76CNDAQ2nmJBpmUQ3Q20BlMomfnaFB6p2Y',
				title 		: 'Holiday Taxis',
				type 		: 'Provider',
				description	: 'HolidayTaxis es un servicio de transporte de escala mundial que ofrece traslados entre aeropuertos y ciudades y resorts en más de 21 000 destinos y 150 países de todo el mundo.',
				action		: 'welcomeForm'
			},
			{
				id 			: 2,
				img 		: 'https://www.conector.com/wp-content/uploads/tourinia-logo-1.jpg',
				title 		: 'Tourinia',
				type 		: 'Provider',
				action		: 'voucher'
			},
			{ 
				id 			: 3,
				img 		: 'https://www.conector.com/wp-content/uploads/tourinia-logo-1.jpg',
				title 		: '_NO_PROVIDER',
				type 		: 'Provider',
				action		: 'welcomeForm'
			}
		]
	};
	icons				: any 		= {			
		cards	: {
			unknown    	: "assets/img/cards/unknown.png"        ,
			none       	: "assets/img/cards/none.png"           ,
			visa       	: "assets/img/cards/visa_icon.png"      ,
			mastercard 	: "assets/img/cards/mastercard_icon.png",
			paypal 		: "assets/img/cards/paypal.png"
		}
	};
	noImage             : String;
	noAvatar            : String;
	environment         : string = "prod";
	factories           : any = { 
		"dev"   : { url : "https://admin.tourinia.com/app_dev.php"	},
		"prod"	: { url : "https://admin.tourinia.com"	}
  	};
  	nationalities		: string[] = [ "es", "uk", "us", "de", "fr", "it", "ru", "ro", "ch" ];
	badges				: any = {};

	constructor(
		public  http				: HttpClient,
		private toastCtrl         	: ToastController  	,
		private translate         	: TranslateService 	,
		private camera            	: Camera           	,
		private screenOrientation 	: ScreenOrientation	,
		private platform          	: Platform         	,
		private fake              	: FakeService     	,
		// private events            	: Events			,
		private vibration			: Vibration			,
		public alertCtrl			: AlertController	,
	) { }

	isCordova()					{	return this.platform.is('cordova');			}
	getFactory()				{	return this.factories[this.environment];	}
	getContent(doc) 			{	return doc.querySelector('ion-content');  	}
	setBadge(type,key,value)	{	
		this.badges[type]				= this.badges[type] || {};
		this.badges[type][key] 			= value;
		this.badges[type]["services"] 	= 2;
		// this.events.publish('badge:'+type, this.badges[type] );
	}
	getBadges(type:string)		{	return this.badges[type];}
	getFakeImage()				{	return this.fake.getBase64Image();	
								}

	print(info,type,position)	{	
		switch(type){
			default:
			case "toast"	:	this.showToast(info,position || "middle");
			break;
			case "alert"	:	alert(info);
			break;
			case "console"	:	console.log(info);
			break;
		}
	}

	/*------------------------------
	* REQUEST API METHODS
	------------------------------*/

	async getRequest(request:any)
	{
		let url 		= request instanceof Object ? request.fullUrl : request;
		let params		= request instanceof Object ? request.params  : {};
		let response	= await Promise.resolve(this.http.get(url,{ params: params }).toPromise());											
		// console.log("Response",response);
		console.groupEnd();
		return response;
	}
	
	getRoute(route:string, params?:any){    
		let current;
		params	=	params || {}; // fix the error on Eval if FN
		switch(this.environment){
			case "prod":	
				current = routes["remote"][routes["remote"][route]?route:"empty"];		// Get element
				current = (current instanceof Function) ? current(params):current;		// Eval if FN
				if(current instanceof Object){											// Add params
					current.fullUrl = this.getServerUrl().concat(current.url);
					//current.fullUrl.concat("?",Object.keys(current.params).reduce((acc,k)=>acc+"&"+k+"="+current.params[k],""));
				}
				break;
					
			default:
			case "dev":	
				current = routes["local"][routes["local"][route]?route:"empty"];
				if(current instanceof Object){
					current.fullUrl = this.getServerUrl().concat(current.url);					
					//current.fullUrl.concat("?",Object.keys(current.params).reduce((acc,k)=>acc+"&"+k+"="+current.params[k],""));
				}
				break;
		}
		return current;	
	}

	getServerUrl(){		if (undefined === this.getFactory() || undefined === this.getFactory().url ){
							// console.groupEnd();
							return false;
						}
						// console.groupEnd();
						return this.getFactory().url;
	}

	/*------------------------------
	* TRANSLATE METHODS
	------------------------------*/

	initTranslate(_lang='en')				{	
		this.translate.setDefaultLang(_lang);
		// HACK
		// this.storage.get('active_lang').then(lang =>{
		// 	console.log("LANG",lang);
		// 	if(lang){
		// 		this.translate.use(lang);
		// 	} else {
		// 		if (this.translate.getBrowserLang() !== undefined) {
		//  			console.log('BROWSER LANG', this.translate.getBrowserLang());
		// 			this.translate.use(this.translate.getBrowserLang());
		// 		} else {
		// 			this.translate.use(_lang); // DEFAULT LANG
		// 		}
		// 	}
		// });
	}

	getCurrentLang()						{	return this.translate.currentLang || this.translate.getBrowserLang();}

	async getTranslate(info)				{	let values = await Promise.resolve(this.translate.get([info]).toPromise());
												return values[info];
											}

	translateDebug(info,type?,position?)	{	
		this.translate.get([info]).subscribe(values => {
			this.debug(values[info],type,position);
		});
	}
	
	debug(info,type?,position?)				{	
		this.print("[DEBUG] "+info,type,position);		
	}

	translateWarning(info,type?,position?)	{	this.translate.get([info]).subscribe(values => {
													this.warning(values[info],type,position);
												});
											}
	warning(info,type?,position?)			{	this.print("[WARNING] "+info,type,position);	}

	translateError(info,type?,position?)	{	this.translate.get([info]).subscribe(values => {
													this.error(values[info],type,position);
												});
											}
	error(info,type?,position?)				{	this.print(info,type,position);					}

	translatePrint(info,type?,position?)	{	this.translate.get([info]).subscribe(values => {
													this.print(values[info],type,position);
												});
											}

	getStatusPage(params){
		if(!params)							{	return 'login'};
		if(!params.info.control.isLogged)	{	return 'login'};
		if(!params.info.control.hasCurrent)	{	//If has no current check if already has one trip, otherwise go to voucher code
			if(!params['listTrips'] || params['listTrips'].length == 0){
				return 'checkin';
			}else{
				return 'ListBookingsPage'
			}
		}
		const page = this.getCurrentPageFromStatus(params.info.control.status);
		if(page=="init"){
			params.info.status = 'INIT';
		}
		return page;
		
	}

	getCurrentPageFromStatus($status){
		switch($status)
		{
			case '_NOT_LOGGED'					:	return 'login';
			case 'INIT'							:	return 'first';
			case '_ARRIVAL_GATE'				:	return 'finger';
			case '_ARRIVAL_BAGGAGE_CLAIM'		:	return 'map-belt';
			case '_ARRIVAL_BELT'				:	return 'belt';			
			case '_MEETING_POINT'				:	return 'map-belt-meetingpoint';
			case '_WAITING_POINT_GROUP'			:	return 'map-route';
			// case '_WAITING_POINT_GROUP'		:	return 'tracking-route';
			case '_WAITING_POINT_TRANSPORT'		:	return 'map-transport';
			// case '_WAITING_POINT_TRANSPORT'	:	return'tracking-route';
			case 'STAY'							:	return 'main';
			case 'Departure'					:	return 'init';
			default								:	return "init";
		}
	}

	async showToast(str: string, position: any, duration?:number ) {
	    let toast = await this.toastCtrl.create({
	      message         : str,
	      duration        : duration || 2000,
	      position        : position || 'middle',
	    });
	    toast.present();
	}

	async alertConfirm(title, subtitle?){
		return new Promise(async (resolve, reject) =>{
		const confirm	=	await this.alertCtrl.create({
			header			: 	await Promise.resolve(this.getTranslate(title)),
			subHeader		:	subtitle ? await Promise.resolve(this.getTranslate(subtitle)) : null,
			buttons			:	[
				{
					text	:	await Promise.resolve(this.getTranslate('_CANCEL')),
					handler	:	() =>{ 	confirm.dismiss().then(()=> resolve(false));
										return false
									}
				},
				{
					text	:	await Promise.resolve(this.getTranslate('_ACCEPT')),
					handler	:	() =>{ 	confirm.dismiss().then(()=> resolve(true)); 
										return false
									}
				}
			]
		});
		return confirm.present();
		});
	}

	checkDifferenceTime(date):string
	{
		let value		= "";
		if(!date){
			return value;
		}
		let dayService	=	moment(date);
		let today		=	moment(new Date());
		let duration	=	moment.duration(dayService.diff(today)).asDays();
		let diff		=	Math.trunc(duration+1);

		switch(diff){
			case 0	: value = "_TODAY"; 	break;
			case 1	: value = "_TOMORROW";	break;
			default	: value = diff.toString(); break;
		}

		return value;
	}

	/**
	 * 
	 * @param $event item to change
	 * @param data array items
	 * @param property property to toggle
	 * @param multipleSelection if enable more than one property toggled
	 */
	toggleItem($event,data, property, multipleSelection?)	{	let tmp	=	$event[property] ? !$event[property] : true;
																if (!multipleSelection){data.forEach(el => el[property] = false);}
																$event[property] = tmp;
	} 


	getInfoDmc(){
		return {
			id			:	'1',
			name			:	'Tourinia',
			destination	:	'1'
		}
	}

	/*------------------------------
	* PLUGINS METHODS
	------------------------------*/
	vibrate()					{	this.vibration.vibrate(100);				}	
	/**
	 * Take picture 
	 * @param source  < "camera" | "gallery " > 
	 * @param params 
	 * @param callback returns { img: jpeg/base64 }
	 */
	takePicture(source,params,callback){    
	    let sourceType:any;
	
	    switch(source){
	      case "camera":
	        sourceType = this.camera.PictureSourceType.CAMERA;
	        break;
	      case "gallery":
	      default:
	        sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;      
	    }
	
	    //let origOrientation = this.screenOrientation.type;
	
	    /*
	    this.debug("ORIENTATION->"+params.orientation,"alert");
	    
	    switch(params.orientation){
	      case "landscape":
	        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
	        break;
	      case "portrait":
	        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
	        break;
	    }
	    */
	    
	    this.camera.getPicture({
			sourceType            : sourceType,
			encodingType          : this.camera.EncodingType.JPEG,
			destinationType       : this.camera.DestinationType.DATA_URL,
			//cameraDirection       : 0,
			correctOrientation    : false,
			//quality               : 100,
			allowEdit             : true,
			//cameraDirection       : 0,
			//targetWidth           : 2048,
			//targetHeight          : 2048,
			saveToPhotoAlbum      : false
	    })
	    .then((ImageData)=>{
			callback({ img: 'data:image/jpeg;base64,'+ImageData });
			this.screenOrientation.unlock();
	    },(error)=>{
			this.error(error);
			this.screenOrientation.unlock();
	    });
  	}

}
