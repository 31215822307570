import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {	path: '',						redirectTo: 'login',	pathMatch: 'full'																										},
  {	path: 'folder/:id',				loadChildren: () => import('./folder/folder.module'										).then( m => m.FolderPageModule)																},
  {	path: 'login',					loadChildren: () => import('./pages/first/login/login/login.module'						).then( m => m.LoginPageModule)												},
  {	path: 'signup',					loadChildren: () => import('./pages/first/signup/signup/signup.module'					).then( m => m.SignupPageModule)											},
  {	path: 'checkin',				loadChildren: () => import('./pages/arrival/checkin/checkin.module'						).then( m => m.CheckinPageModule)											},
  {	path: 'list-bookings',			loadChildren: () => import('./pages/arrival/list-bookings/list-bookings.module'			).then( m => m.ListBookingsPageModule)							},
  {	path: 'first',					loadChildren: () => import('./pages/arrival/first/first.module'							).then( m => m.FirstPageModule)													},
  {	path: 'finger',					loadChildren: () => import('./pages/arrival/finger/finger.module'						).then( m => m.FingerPageModule)												},
  {	path: 'belt',					loadChildren: () => import('./pages/arrival/belt/belt.module'							).then( m => m.BeltPageModule)														},
  {	path: 'waiting',      			loadChildren: () => import('./pages/arrival/waiting/waiting.module'						).then( m => m.WaitingPageModule)											},
  
  {	path: 'map-belt',				loadChildren: () => import('./pages/arrival/map-belt/map-belt.module'					).then( m => m.MapBeltPageModule)											},
  {	path: 'map-belt-meetingpoint',	loadChildren: () => import('./pages/arrival/map-belt-meetingpoint/map-belt-meetingpoint.module').then( m => m.MapBeltMeetingpointPageModule)	},
 
  {	path: 'meetingpoint',   		loadChildren: () => import('./pages/arrival/meetingpoint/meetingpoint.module'			).then( m => m.MeetingpointPageModule)								},
  {	path: 'map-meetingpoint',		loadChildren: () => import('./pages/arrival/map-meetingpoint/map-meetingpoint.module'	).then( m => m.MapMeetingpointPageModule)					},
 
  {	path: 'map-route',				loadChildren: () => import('./pages/arrival/map-route/map-route.module'					).then( m => m.MapRoutePageModule)										},
 
  {	path: 'map-transport',			loadChildren: () => import('./pages/arrival/map-transport/map-transport.module'			).then( m => m.MapTransportPageModule)							},
  {	path: 'transport',				loadChildren: () => import('./pages/arrival/transport/transport.module'					).then( m => m.TransportPageModule)										},
 
  {	path: 'tracking-route',			loadChildren: () => import('./pages/arrival/tracking-route/tracking-route.module'		).then( m => m.TrackingRoutePageModule)						},
  {	path: 'welcome-form',			loadChildren: () => import('./pages/arrival/welcome-form/welcome-form.module'			).then( m => m.WelcomeFormPageModule)								},
  {	path: 'settings',				loadChildren: () => import('./pages/settings/settings.module'							).then( m => m.SettingsPageModule)													},
  {	path: 'issues',					loadChildren: () => import('./pages/issues/issues.module'								).then( m => m.IssuesPageModule)														},
  {	path: 'chat-master',			loadChildren: () => import('./pages/chat/chat-master/chat-master.module'				).then( m => m.ChatMasterPageModule)									},
  {	path: 'chat-detail',			loadChildren: () => import('./pages/chat/chat-detail/chat-detail.module'				).then( m => m.ChatDetailPageModule)									},
  {	path: 'help',					loadChildren: () => import('./pages/help/help.module'									).then( m => m.HelpPageModule)																},
  {	path: 'main',					loadChildren: () => import('./pages/main/main.module'									).then( m => m.MainPageModule)																},
  // {	path: 'main',					loadChildren: () => import('./pages/checkout/checkout.module'							).then( m => m.CheckoutPageModule)													},
  {	path: 'services-bookeds',		loadChildren: () => import('./pages/services-bookeds/services-bookeds.module'			).then( m => m.ServicesBookedsPageModule)							},
  {	path: 'general-map',			loadChildren: () => import('./pages/general-map/general-map.module'						).then( m => m.GeneralMapPageModule)											},
  {	path: 'checkout',				loadChildren: () => import('./pages/checkout/checkout.module'							).then( m => m.CheckoutPageModule)													},
  {	path: 'excursion-info',			loadChildren: () => import('./pages/excursion-info/excursion-info.module'				).then( m => m.ExcursionInfoPageModule)								},
  {	path: 'card-master',			loadChildren: () => import('./pages/card/card-master/card-master.module'				).then( m => m.CardMasterPageModule)									},
  {	path: 'card-detail',			loadChildren: () => import('./pages/card/card-detail/card-detail.module'				).then( m => m.CardDetailPageModule)									},
  {	path: 'init',					loadChildren: () => import('./pages/arrival/init/init.module'							).then( m => m.InitPageModule)														}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
