// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const environment = {
  production: false,
  firebase: {
    apiKey				: "AIzaSyDXGo6MTulw5-o7s-4G3Wioa5KgnY4cRyM",
    authDomain			: "tourinia.firebaseapp.com",
    databaseURL			: "https://tourinia.firebaseio.com",
    projectId			: "tourinia",
    storageBucket		: "tourinia.appspot.com",
    messagingSenderId	: "29132584927",
    appId				: "1:29132584927:web:f81909c8b14cc88348f66a",
    measurementId		: "G-NJKQXK3P2L"
  }
};