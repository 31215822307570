<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of pages; let i = index">
            <ion-item (click)         = "openPage(p)" 
                      routerDirection = "root" 
                      [routerLink]    = "[p.url]" 
                      lines           = "none" 
                      detail          = "false" 
					  [class.selected]= "pageInfo.selectedIndex == i"
					  >
              <ion-icon [name]="p.icon" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
