import { ThrowStmt } from '@angular/compiler';
import { Injectable 		} from '@angular/core';
import { AngularFirestore	} from '@angular/fire/firestore';
import { notStrictEqual } from 'assert';
import { CommonsService 	} from '../commons/commons.service';
import { FirebaseService 	} from '../firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class TripsService {

  constructor(
	private afs			   	:	AngularFirestore,
	private firebaseService	:	FirebaseService,
	public commons 		:	CommonsService,
  ) { }


  	async getCurrentTripById(userId,tripId):Promise<any>{	
		if(!userId || !tripId){
			return { exists: false };
		}
		const path		= `/users/${userId}/trips/${tripId}`;
		const docRef 	= await this.afs.doc(path).ref.get();
		return docRef;
	}

	async getMyTrips(userId)				{	
		return await this.afs.firestore.collection('users').doc(userId).collection('trips').get();		
	}

	async addTrip(userId,trip)				{	
		await this.afs.collection('users').doc(userId).collection('trips').doc(trip.id).set(trip);
	}

	async getRefTrip(userId, tripId)		{	
		return await this.afs.collection('users').doc(userId).collection('trips').doc(tripId).ref.get();
	}

	checkIfCurrentTrip(user,trip)			{	
		return true;							
	}
	
	async markTripAsCurrent(user,trip)	{	
		try {
			await this.afs.collection('users').doc(user.id).update({ current : trip.id });
		}catch(error){
			console.log("[markTripAsCurrent] Error",error.message);
		}
	}


	async checkDmcBooking(dmcId, dmcDestination, voucher){
		const path 	= `dmcs/${dmcId}/destinations/${dmcDestination}/bookings/${voucher}`;
		return await this.afs.doc(path).ref.get();
	}


	async checkAllSteps(userInfo)
	{
		let user 			= 	await this.firebaseService.getUserById(userInfo.id);
		let currentTrip		=	await this.getCurrentTripById(userInfo.id,user.data().current);

		if(undefined==currentTrip){ return false;	}
		
		let currentTripData =	currentTrip.data();

		if(currentTripData){
			if(!currentTripData.addedToGroup){
				await this.addTripCustomerToGroup(user.data(),currentTripData)
			}else{
				console.log('ITS ON THE GROUP');
			}
			
			if(currentTripData.rooms && currentTripData.rooms.length > 0){
				console.log(currentTripData);
				let foundRep=	currentTripData.rooms.find(el => el.profile == 'rep');
				if(!foundRep){
					await this.createChatWithRep(userInfo, user.data());
				}
			}
		}
	}

	async checkIfMarkActive(user){
		// let userInfo	= await this.firebaseService.getUserById(user.id);
		let myTrips 	= await this.afs.firestore.collection(`users/${user.id}/trips`).get();
		let trip		= null;

		myTrips.forEach((doc)=>{
			// Get fake voucher data
			if(doc.id == this.commons.pageInfo.voucheCode){ 
				trip = { id : doc.id, ...doc.data() };				
			}
		 });
		
		 if(trip != null){
			user.trip				= trip;
			user.current 			= trip.id;
			user.reference			= trip.id;
			user.control.hasCurrent = true;

			await this.markTripAsCurrent		(user,trip);
			await this.addTripCustomerToGroup	(user,trip);
			await this.markTripAsCurrent		(user,trip);
			await this.createChatWithRep		(user,trip);

			this.commons.userInfo = user;
		 }
	}

	async createRoom(referenceTripTourist, referenceRep)
	{
		const info		= {	
			isGroup	:false, 
			users	:[ 
				referenceTripTourist,
				referenceRep
			]
		};
		const dataRoom 	= await  this.afs.collection('rooms').add(info);
		return dataRoom;
	}


	/**
	 * SHOULD REFACTOR 
	 * @param customer customer object
	 * @param trip trip object 
	 */
	async addTripCustomerToGroup(customer,trip):Promise<any>
	{
		let message = { "success" : true };
		const groupNotFoundError = "group not found";
	
		try {
			if(!trip.group){
				throw new Error(groupNotFoundError);
			} 
			let groupInfo	=	await this.getGroupById(trip.group);

			if(groupInfo.exists){
				let usersGroup 		= groupInfo.data().users || [];
				let newUser 		= {
					avatar		: customer.avatar,
					name 		: customer.name, 
					pax 		: customer.pax || null,
					hasApp		: true,
					phone 		: customer.phone || null,
					email 		: customer.email || null,
					lodging 	: trip.lodging,
					locale 		: customer.locale,
					groupId 	: groupInfo.id,
					id 			: customer.id,
					ref 		: groupInfo.ref,
					added		: new Date()
				};
				let foundUser 	=	usersGroup.find(user => user.id == newUser.id);
				let refTrip		=	await this.getRefTrip(customer.id,trip.id);
				if(!foundUser){
					usersGroup.push(newUser);
					const updated 	= 	await this.firebaseService.updateItemByRef(groupInfo.ref,{users : usersGroup})
					await this.firebaseService.updateItemByRef(refTrip.ref,{addedToGroup : true});
					message["data"] = 	updated;
				} else {
					if(refTrip.data().addedToGroup == undefined || refTrip.data().addedToGroup == false){
						await this.firebaseService.updateItemByRef(refTrip.ref,{addedToGroup : true})					
					}
				}
			} else {
				throw new Error(groupNotFoundError);
			}
		}catch(error){
			message["success"	] = false;
			message["error"		] = error.message;
		}

		return message;
	}

	async getGroupById(id)	{		
		return await this.afs.collection('groups').doc(id).ref.get();
	}

	async getRepFromGroup(groupId){
		console.log('GETREP GROUP', groupId)
		let repRef	=	await this.afs.collection('groups').doc(groupId).ref.get();
		console.log('the rep from the group', repRef);
		return repRef.data().refRep || '/users/3';
	}

	/**
	 * SHOULD REFACTOR
	 * @param userId 
	 * @param tripId 
	 */
	async createChatWithRep(user,trip)
	{
		try {
			let tripRef			=	await this.getCurrentTripById(user.id,trip.id);
			let tripInfo		=	tripRef.data() || {};
			let infoUser		=	await this.firebaseService.getUserById(user.id);
			const fakeRep		=	"/users/3";
			let repReference 	= 	tripInfo.addedToGroup 
										? await this.getRepFromGroup(tripInfo.group) 
										: fakeRep;										
			let rep  			= 	await this.firebaseService.getItemByRef(repReference);

			let tripRooms		=	tripInfo.rooms || []; //Get the rooms from the current trip
			let foundRoom 		= 	tripRooms.findIndex(room => { 
										room.repReference.toString() == rep.ref.toString()
									}); //Find if already exists a room with the rep
			
			if (foundRoom === -1){
				
				//Create the new room between currentTrip and REP
				let dataNewRoom =	await this.createRoom(tripRef.ref,rep.ref);
				console.log('dataRoom', dataNewRoom);

				// Add new entrance to Trip Rooms from current trip
				let repInfo = rep.data();

				if(repInfo){	
					const newRoomTrip =	{
						avatar  	: rep.data().avatar,
						name 		: rep.data().name,
						profile 	: 'rep',
						repReference: rep.ref,
						reference	: dataNewRoom,
						id			: dataNewRoom.id
					};
					
					tripRooms.push(newRoomTrip);
					await this.firebaseService.updateItemByRef(tripRef.ref, {rooms : tripRooms});
					// Add new entrance to Rep Rooms from current trip
					
					const newRoomRep = {
						avatar            		: infoUser.data().avatar || null,
						name              		: infoUser.data().name	 || '_unknown_name',
						profile           		:'customer',
						room              		: Math.floor(Math.random() * 500),
						group             		: tripInfo.group || 'NO GROUP',
						hasApp            		: true,
						pax               		: tripInfo.pax || 2,
						currentTripReference 	: infoUser.ref,
						// customerReference : infoUser.ref,
						reference         		: dataNewRoom,
						id						: dataNewRoom.id
					}
					let repRooms = rep.data().rooms || [];
					repRooms.push(newRoomRep);
					await this.firebaseService.updateItemByRef(rep.ref.path,{rooms : repRooms});
				} 
				else 		{	
					console.log("[tripService:createChatWithRep] No rep data found");	
				}
			}
			else	{	
				console.log('[tripService:createChatWithRep] Room is already created with this trip and rep');	
			}
		} catch(e){
			console.log("[tripService:createChatWithRep] Error",e)
		}
	}

	async createTripWithNoVoucher(user){
		const path		=	`/users/${user.id}/trips`;
		let tripCreated	=	await this.afs.collection(path).add({});
		let refTrip		=	await tripCreated.get();
		
		const response 	= { 
			id :	tripCreated.id,
			ref :	refTrip.ref
		};

		return response;
	}

	async checkIfBookingAlreayAdded(reference){
		console.log(reference);
		const path		= `/collection/bookings/checkins/${reference}`;
		const responser = await this.afs.doc(path).ref.get();
	}

	async checkIfBookingExist(reference){
		return await this.afs.doc(`/bookings/${reference}`).ref.get();
	}

	/**
	 * Method which create a room chat to the customers from the current voucher. Actually is not used. 06/08/2019. 
	 * @param user user which put the vouhcer code
	 * @param booking room from the booking using as id
	 */
	async addBookingChecked(user, bookingId)
	{
		let userRef		= await this.firebaseService.getUserById(user.id);
		let infoRoom	= {
			name	:	'family',
			chatRoom: 	null,
			items	: 	[{
				ref		:	userRef.ref,
				isOwner	:	true,
				role	:	'_ADMIN',
				privs	:	'_ALL'
			}]
		};
		const path		= `/grouping/bookings/checkins/${bookingId}`;
 		const response 	= await this.afs.doc(path).set(infoRoom);
		return response;
	}

	/**
	 * restart app for simulation purposes
	 * 
	 * @param user
	 * @returns 
	 */
	async restartTest(user):Promise<boolean>
	{
		let userToReset		=	await this.firebaseService.getUserById(user.id);
		const dmc			=	this.commons.userInfo.trip.dmc;
		const destination	=	this.commons.userInfo.trip.destination;
		const reference		=	this.commons.userInfo.trip.reference;

		if(undefined==reference){
			this.commons.translateError("_REFERENCE_NOT_FOUND");
			return false;
		}
		const path		=	`/users/${user.id}/trips/${reference}`;
		let infoDoc		=	await this.afs.doc(path).ref.get();
		
		if(!infoDoc.exists){ 
			this.commons.translateDebug('_TEST_USER_RESTARTED'); 
			return true;
		}
		
		await this.afs.doc(`/users/${user.id}/trips/${reference}`).delete();

		let userUpdated			=	userToReset.data();
		userUpdated.control		=	{
			hasCurrent		:	false,
			isLogged		:	false,
			status			:	'INIT',
			statusProcess	:	'INIT'
		};
		userUpdated.current		=	null;
		userUpdated.listTrips	=	[];

		await this.afs.doc(`/users/${user.id}`).update(userUpdated);
		await this.afs.doc(`/grouping/bookings/checkins/${reference}`).delete();
		await this.afs.doc(`/dmcs/${dmc}/destinations/${destination}/bookings/${reference}`)
					.update({customer_status : null});
		
		this.commons.translateDebug('TEST USER RESTARTED');
		return true;
	}
	
	// WHAT TO DO WITH THESE METHODS

	//need to define the process to assign a trip to a group
	async assignTripToGroup( user, current)	{	
		return null;	
	}
}
